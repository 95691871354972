UneekSso.UserSessions = $.extend({
  onLoad: function () {
    var modal = $(UneekSso.UserSessions.modalSelector);
    modal.on("show.bs.modal", UneekSso.UserSessions._showModal);
    modal.on("hide.bs.modal", UneekSso.UserSessions._hideModal);
    modal.on("submit", UneekSso.UserSessions._submitModal);
  },

  getTabsSelector: function () {
    return UneekSso.UserSessions.modalSelector + " .modal-header .nav-link";
  },

  getInactiveTabsSelector: function () {
    return UneekSso.UserSessions.getTabsSelector() + ":not(.active)";
  },

  _getModalContent: function (modal) {
    return modal.find(".modal-content");
  }
}, UneekSso.UserSessions);

UneekSso.onLoad(UneekSso.UserSessions.onLoad);
