if (!"UneekSso" in window)
  window.UneekSso = {};

var UneekSso = window.UneekSso;

UneekSso.onLoad = UneekSso.onLoad || function (f) {
  if (typeof this._toLoad === "undefined")
    this._toLoad = [];
  this._toLoad.push(f);
};

UneekSso.load = UneekSso.load || function () {
  if (typeof this._toLoad === "undefined")
    return;
  while (this._toLoad.length > 0) {
    UneekSso._loadFunction(this._toLoad.shift());
  }
};

UneekSso.UserSessions = UneekSso.UserSessions || {};
