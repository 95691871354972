UneekSso._loadFunction = UneekSso._loadFunction || function (f) {
  if (typeof Turbolinks === "undefined")
    $(f);
  else
    $(document).on("turbolinks:load", f);
};

UneekSso.UserSessions = $.extend({
  modalSelector: null, // user defined
  defaultAjaxOptions: {},
  showModalAjaxOptions: {},
  submitModalAjaxOptions: {},

  _sendAjax: function (action, ajaxOptions) {
    $.ajax($.extend({}, UneekSso.UserSessions.defaultAjaxOptions, ajaxOptions, UneekSso.UserSessions[action + "AjaxOptions"] || {}));
  },

  // redefined
  _getModalContent: function (modal) {
    return null;
  },

  _loadModalContent: function (data) {
    var $modal = $(UneekSso.UserSessions.modalSelector);
    var modalContent = UneekSso.UserSessions._getModalContent($modal);
    if (!UneekSso.UserSessions._oldContent)
      UneekSso.UserSessions._oldContent = modalContent.html();
    modalContent.html(data);
    $modal.trigger("loginFormLoaded.uneekSso");
  },

  _restoreModalContent: function () {
    var $modal = $(UneekSso.UserSessions.modalSelector);
    if (UneekSso.UserSessions._oldContent) {
      var modalContent = UneekSso.UserSessions._getModalContent($modal);
      modalContent.html(UneekSso.UserSessions._oldContent);
      UneekSso.UserSessions._oldContent = null;
    }
    $modal.trigger("loginFormUnloaded.uneekSso");
  },

  _showModal: function (e) {
    UneekSso.UserSessions._sendAjax("showModal", {
      url: UneekSso.UserSessions.getCasLoginUrl(),
      type: "GET",
      dataType: "html",
      success: function (data, textStatus, xhr) {
        UneekSso.UserSessions.manageRenderServiceXhr(xhr, function () {
          UneekSso.UserSessions._loadModalContent(xhr.responseText);
        }, true);
      },
      error: function (xhr) {
        UneekSso.UserSessions.loginFailed(xhr);
      },
      crossDomain: false,
      xhrFields: {
        withCredentials: true
      }
    });
  },

  _hideModal: function (e) {
    UneekSso.UserSessions._restoreModalContent();
  },

  _submitModal: function (e) {
    e.preventDefault();
    e.stopPropagation();

    var form = e.target;
    UneekSso.UserSessions._sendAjax("submitModal", {
      url: form.action,
      type: form.method || "POST",
      data: $(form).serializeArray(),
      dataType: "html",
      success: function (data, textStatus, xhr) {
        UneekSso.UserSessions.manageRenderServiceXhr(xhr, UneekSso.UserSessions.loginSucceeds, true);
      },
      error: function (xhr) {
        UneekSso.UserSessions.loginFailed(xhr);
      },
      crossDomain: false,
      xhrFields: {
        withCredentials: true
      }
    });

    return false;
  },

  _loginFailedUserError: function (xhr) {
    UneekSso.UserSessions._loadModalContent(xhr.responseText);
  },

  _loginFailedServerError: function (xhr) {
    UneekSso.UserSessions._restoreModalContent();
  }
}, UneekSso.UserSessions);
