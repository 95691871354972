UneekSso._loadFunction = UneekSso._loadFunction || function (f) {
  if (typeof Turbolinks === "undefined")
    window.addEventListener("load", f);
  else
    window.addEventListener("turbolinks:load", f);
};

UneekSso.UserSessions.getCasBaseLoginUrl = UneekSso.UserSessions.getCasBaseLoginUrl || function () {
  return UneekSso.UserSessions.casBaseLoginUrl; // user defined
};

UneekSso.UserSessions.getCasLoginUrl = UneekSso.UserSessions.getCasLoginUrl || function () {
  var baseUrl = UneekSso.UserSessions.getCasBaseLoginUrl();
  if (UneekSso.UserSessions.theme) {
    var connectChar = (baseUrl.indexOf("?") > 0) ? "&" : "?";
    return baseUrl + connectChar + "theme=" + encodeURI(UneekSso.UserSessions.theme);
  } else {
    return baseUrl;
  }
};

UneekSso.UserSessions.manageRenderServiceXhr = UneekSso.UserSessions.manageRenderServiceXhr || function (xhr, f, formOpened) {
  if (xhr.status === 201) {
    // already connected
    UneekSso.UserSessions.renderServiceXhr(xhr, formOpened); // TODO if reload cable: on any XHR request here
  } else {
    f(xhr);
  }
};

UneekSso.UserSessions.renderServiceXhr = UneekSso.UserSessions.renderServiceXhr || function (xhr, formOpened) {
  UneekSso.UserSessions.loginSucceeds(xhr);
};

UneekSso.UserSessions.loginSucceeds = UneekSso.UserSessions.loginSucceeds || function (xhr) {
  // TODO data contains the page content; it is stupid to redirect to it... (see replace())
  if (typeof Turbolinks === "undefined")
    location.reload();
  else
    Turbolinks.visit(location); // TODO reload cable: and here
};

UneekSso.UserSessions.loginFailed = UneekSso.UserSessions.loginFailed || function (xhr) {
  if ((xhr.status === 0) && (xhr.readyState === 0)) {
    // bug with Firefox: impossible to follow redirection
    UneekSso.UserSessions._loginFailedFirefoxRedirection(xhr);
  } else if ((xhr.status >= 400) && (xhr.status < 500)) {
    // user error
    UneekSso.UserSessions._loginFailedUserError(xhr);
  } else {
    // other error, probably server
    UneekSso.UserSessions._loginFailedServerError(xhr);
  }
};

UneekSso.UserSessions._loginFailedFirefoxRedirection = UneekSso.UserSessions.loginFailedFirefoxRedirection || function (xhr) {
  // we should be connected to SSO server, therefore we redirect manually to service URL
  window.location = UneekSso.UserSessions.getCasLoginUrl();
};

UneekSso.UserSessions._loginFailedUserError = UneekSso.UserSessions._loginFailedUserError || function (xhr) {
  console.warn(xhr.responseText);
};

UneekSso.UserSessions._loginFailedServerError = UneekSso.UserSessions._loginFailedServerError || function (xhr) {
  // TODO
  console.error(xhr.responseText);
};

UneekSso.onLoad(function () {
  if (typeof Turbolinks === "undefined")
    return;
  // logout with Turbolinks
  document.addEventListener("turbolinks:request-start", function (event) {
    if (!UneekSso.UserSessions.isLogoutUrl || !UneekSso.UserSessions.isLogoutUrl(event.data.url))
      return;
    event.data.xhr.withCredentials = true;
  });
});

UneekSso.load();
