/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { requireAll } from "../utils"

import "../assets"

require("@rails/ujs").start()
//require("@rails/activestorage").start()

require("jquery")
//= require casino // TODO?

require("turbolinks").start()

// see for lazy-loading: https://github.com/rails/webpacker/issues/1944#issuecomment-464126096
import "popper.js"
import "bootstrap"

import "select2"
import "dropzone"

import "../form/data_set_value"
import "../form/dynamic_fields"
import "../form/image_field"

import "../client"

requireAll(require.context("../monkey_patch", true, /\.js$/))
