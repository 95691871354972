// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

export default (function() {
  let createConsumer
  if ((typeof ActionCable !== "undefined") && !ActionCable.createWebSocketURL) { // filter actioncable >= 6 (Action.createWebSocketURL Action.createWebSocketURL used to be a function)
    if (typeof Promise !== "undefined") {
      createConsumer = Promise.resolve(ActionCable.createConsumer)
    } else { // for Mathusalem Explorer
      createConsumer = {
        then: () => {
          return ActionCable.createConsumer
        }
      }
    }
  } else {
    createConsumer = import("@rails/actioncable").then((actioncable) => actioncable.createConsumer)
  }

  return createConsumer.then(f => {
    this.App || (this.App = {})
    return this.App.cable = f(this.url + "/cable")
  })
}).call(UneekSso)
