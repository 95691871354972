/* Automatically show a tab if the window location hash refers to it */

$(document).on("turbolinks:load", function (event) {
  if (window.location.hash) {
    $("a.tab-auto-location[role=tab][href=\\" + window.location.hash + "]").tab("show");
  } else {
    // TODO try to remove tab-auto-location-default with caching the opened tab on each page on its first loading
    var defaultTab = $("a.tab-auto-location.tab-auto-location-default[role=tab]");
    defaultTab.data("bootstrap-tab-auto-location", window.location); // kinda crappy... D=
    defaultTab.tab("show");
  }
});


/* Automatically set the window location hash when clicking on a tab */

$(document).on("show.bs.tab", "a.tab-auto-location[role=tab]:not(.tab-auto-location-default)", function (event) {
  var $target = $(event.target);
  var href;
  if ($target.hasClass("tab-auto-location-default") && $target.data("bootstrap-tab-auto-location")) {
    href = $target.data("bootstrap-tab-auto-location");
    $target.removeData("bootstrap-tab-auto-location");
  } else {
    href = $target.attr("href");
  }

  if (href !== window.location.hash) // to prevent to add a new history entry on reload
    Turbolinks.controller.pushHistoryWithLocationAndRestorationIdentifier(href, Turbolinks.uuid()); // NOTE: Turbolinks private method, undocumented: may not work in future versions...
});
