import consumer from "./consumer"
consumer.then((consumer) => {

(function() {
  if (!this.App)
    return;

  this.App.notification = consumer.subscriptions.create("NotificationChannel", {
    received: function (data) {
      for (var k in data) {
        switch(k) {
          case "notification":
            this._sendNotification(data[k]);
            break;
        }
      }
    },

    updateNotificationStatus: function (id, status, reason) {
      if (typeof id === "undefined")
        return;
      this.perform("update_notification_status", { id: id, status: status, reason: reason });
    },

    _sendNotification: function (params) {
      var cable = this;
      return this._withGrantedNotifications(function () {
        cable._constructNotification(params);
      }, function (reason) {
        return cable._rejectNotification(params, reason);
      });
    },

    _withGrantedNotifications: function (resolve, reject) {
      if (!"Notification" in window) {
        return reject("unsupported");
      } else if (Notification.permission === "granted") {
        return resolve();
      } else if (Notification.permission === "denied") {
        return reject("denied");
      } else {
        Notification.requestPermission(function (permission) {
          if (!"permission" in Notification)
            Notification.permission = permission;

          if (permission === "granted")
            return resolve();
          else
            return reject("denied");
        });
      }
    },

    _constructNotification: function (params) {
      var cable = this;
      var notification = new Notification(params.title, params);
      notification.onshow = function () {
        cable.updateNotificationStatus(params.id, "received");
      };
      notification.onclick = function () {
        cable.updateNotificationStatus(params.id, "read");
        if (params.link) {
          window.open(params.link);
        }
      };
      notification.onerror = function () {
        cable._rejectNotification(params, "client_error");
      };
      return notification;
    },

    _rejectNotification: function (params, reason) {
      this.updateNotificationStatus(params.id, "rejected", reason);
    }
  });
}).call(UneekSso);

})
