import("jquery").then(({ default: $ }) => {

$(document).on("change", "[data-set-value]", function () {
  const $this = $(this);
  const data = $this.data("set-value");

  let targets;
  if (typeof data === "string") {
    targets = [$(data)];
  } else {
    targets = $.map(data["targets"], (t) => $(t));
  }
  let sources = data["sources"] || $.map(targets, () => "value");

  const input = (this.tagName === "SELECT") ? $(this.selectedOptions[0]) : $this;

  $.each(targets, function (i) {
    console.log(this, sources[i], input.attr(sources[i]));
    $(this).val(input.attr(sources[i]));
  });
});

});
